"use client";

import { useState } from "react";
import { BlockRenderer } from "@litonarefin/components/BlockRenderer";
import { isEmpty } from "lodash";
import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
import StyledIcon from "../StyledIcon";
// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const Switcher = ({ attributes, blocks }) => {
    const [openPriceIndex, setOpenPriceIndex] = useState(0);
    // const allAOS = getAOSAnimation(attributes)

    const { style = "style1", tabWithIcons = [], switcherTabs } = attributes;

    return (
        <div
            className="jlt-flex jlt-flex-col jlt-justify-center"
            {...(attributes?.anchor && { id: attributes?.anchor })}>
            <div className={style !== "style2" ? "jlt-m-auto" : "jlt-max-w-[1216px] jlt-mx-auto"}>
                {style === "style1" ? (
                    <div className="jlt-bg-white jlt-p-1 jlt-inline-flex jlt-rounded-[40px] jlt-switcher-btns jlt-border">
                        {switcherTabs?.map((tab, index) => (
                            <button
                                key={index}
                                onClick={() => setOpenPriceIndex(index)}
                                className={`jlt-relative jlt-rounded-[40px] jlt-shadow-[0px_0px_20px0_rgba(173,173,188,0.15)] jlt-text-sm jlt-font-bold jlt-py-3 jlt-px-8 jlt-border-none jlt-block jlt-cursor-pointer ${
                                    openPriceIndex == index
                                        ? "jlt-bg-[--primary] jlt-text-white jlt-switcher-active-btn"
                                        : "jlt-text-[#535860]"
                                }`}>
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: capitalizeFirstLetter(tab.title),
                                    }}
                                />

                                {!!tab?.tooltip?.open ? (
                                    <span
                                        style={{
                                            background: "#DCFAE6",
                                            color: "#074D31",
                                        }}
                                        className="jlt-hurry-notice jlt-absolute -jlt-top-12 jlt-left-1/2  jlt-text-xs jlt-font-semibold jlt-py-[6px] jlt-px-2 jlt-w-max jlt-rounded after:jlt-absolute after:jlt-top-full after:jlt-left-1/4 after:jlt-w-0 after:jlt-h-0 after:jlt-border-l-0 after:jlt-border-l-transparent after:jlt-border-r-[12px] after:jlt-border-r-transparent after:jlt-border-t-[12px] after:jlt-border-t-[#DCFAE6]"
                                        dangerouslySetInnerHTML={{
                                            __html: tab?.tooltip?.text,
                                        }}
                                    />
                                ) : null}
                            </button>
                        ))}
                    </div>
                ) : null}

                {style === "style2" ? (
                    <div
                        className="jlt-flex jlt-flex-wrap jlt-mx-4 sm:jlt-mx-0"
                        style={{
                            gridTemplateColumns: `repeat(${switcherTabs?.length}, 1fr)`,
                        }}>
                        {switcherTabs?.map((tab, index) => (
                            <button
                                key={index}
                                onClick={() => setOpenPriceIndex(index)}
                                className={`jlt-h-full jlt-p-4 sm:jlt-px-8 sm:jlt-pt-6 sm:jlt-pb-4 jlt-flex jlt-items-center jlt-justify-center jlt-flex-col ${
                                    openPriceIndex == index
                                        ? "jlt-bg-[#F7FAFC] jlt-text-[#1A202C] jlt-rounded-tl-lg jlt-rounded-tr-lg"
                                        : "jlt-text-[#40444e]"
                                }`}>
                                {tab?.icon ? (
                                    <StyledIcon
                                        color={openPriceIndex == index ? "#1F1FC1" : "#718096"}>
                                        <span
                                            className="jlt-hidden sm:jlt-block"
                                            dangerouslySetInnerHTML={{
                                                __html: tab.icon,
                                            }}
                                        />
                                    </StyledIcon>
                                ) : null}
                                <span className="jlt-mt-0 sm:jlt-mt-2 jlt-text-sm jlt-leading-6 jlt-font-semibold">
                                    {tab.title}
                                </span>
                            </button>
                        ))}
                    </div>
                ) : null}

                <style jsx>{`
                    .jlt-switcher-btns {
                        ${!isEmpty(attributes?.switcherMargin?.desktop)
                            ? getDimensionCSS(attributes?.switcherMargin?.desktop, "margin")
                            : ""}
                    }
                `}</style>
            </div>
            <BlockRenderer blocks={blocks?.[openPriceIndex].innerBlocks} />
        </div>
    );
};

export default Switcher;
