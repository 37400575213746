"use client";

import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import isEmpty from "lodash/isEmpty";
// import { lowerCaseWithOutSpace } from "@litonarefin/utils/lowerCaseWithOutSpace";
import axios from "axios";
import { getIcon } from "@litonarefin/utils/icons";
import { getStyledIcon } from "@litonarefin/utils/getStyledIcon";

export const DocsSearch = ({ pageSlug }) => {
    const [searchText, setSearchText] = useState("");
    const [categoryId, setCategoryId] = useState("");
    const [parentCategories, setParentCategories] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [open, setOpen] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [loading, setLoading] = useState(false);

    let dropRef = useRef();

    const getSingleDocSearch = async (cateId, searchQuery) => {
        const { data } = await axios.post(`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/query`, {
            query: `
                    query GET_SINGLE_DOCS($search: String = "", $id: ID = "") {
                        ${pageSlug}DocCategory(id: $id) {
                            children {
                                nodes {
                                    name
                                    ${pageSlug}Docs(where: {search: $search}) {
                                        nodes {
                                            title
                                            slug
                                            ${pageSlug}DocsCategories {
                                                nodes {
                                                    name
                                                    slug
                                                    parent {
                                                        node {
                                                            name
                                                            slug
                                                        }   
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            ${pageSlug}Docs {
                                nodes {
                                    title
                                    slug
                                    ${pageSlug}DocsCategories {
                                        nodes {
                                            name
                                            slug
                                            parent {
                                                node {
                                                    name
                                                    slug
                                                }   
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                `,
            variables: {
                id: cateId,
                search: searchQuery,
            },
        });

        let searchArr = [];
        let parentDocs = data?.data?.[`${pageSlug}DocCategory`]?.[`${pageSlug}Docs`]?.nodes;
        let categoryDocs = data?.data?.[`${pageSlug}DocCategory`]?.children?.nodes;

        //parent docs set to empty array
        if (!isEmpty(parentDocs)) {
            parentDocs?.map((doc) => searchArr.push(doc));
        }

        //category docs set to empty array
        if (!isEmpty(categoryDocs)) {
            categoryDocs?.map((cateDocs) => {
                let cateDocsCheck = cateDocs?.[`${pageSlug}Docs`]?.nodes;
                if (!isEmpty(cateDocsCheck)) {
                    cateDocsCheck?.map((doc) => searchArr.push(doc));
                }
            });
        }

        setSearchData(searchArr || []);
        setLoading(false);
        setIsSearch(false);
    };

    const getAllDocsName = async () => {
        const { data } = await axios.post(`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/query`, {
            query: `
                    query GET_ALL_DOCS_NAME {
                        ${pageSlug}DocsCategories {
                            nodes {
                                name
                                slug
                                id
                                parent {
                                    node {
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                    }
                `,
        });

        const categories = data?.data?.[`${pageSlug}DocsCategories`]?.nodes;
        if (!isEmpty(categories)) {
            let result = categories?.filter((category) => category?.parent === null);
            setParentCategories(result);
        }
    };

    const getAllDocsSearch = async (searchQuery) => {
        const { data } = await axios.post(`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/query`, {
            query: `
                query GET_ALL_DOCS($search: String = "") {
                    ${pageSlug}Docs(where: {search: $search}) {
                        nodes {
                            title
                            slug
                            ${pageSlug}DocsCategories {
                                nodes {
                                    name
                                    slug
                                    parent {
                                        node {
                                            name
                                            slug
                                        }   
                                    }
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                search: searchQuery,
            },
        });
        setSearchData(data?.data?.[`${pageSlug}Docs`]?.nodes || []);
        setLoading(false);
        setIsSearch(false);
    };

    useEffect(() => {
        getAllDocsName();
    }, []);

    useEffect(() => {
        if (!categoryId) return;

        let interval;

        document.querySelector("#txtSearch").addEventListener("keypress", function (e) {
            if (e.key === "Enter") {
                if (!!searchText) {
                    setOpen(true);
                    setLoading(true);
                    interval = setTimeout(() => {
                        getAllDocsSearch(searchText);
                    }, 300);
                } else {
                    setOpen(false);
                }
            }
        });

        if (!!isSearch) {
            if (!!searchText) {
                setOpen(true);
                setLoading(true);
                interval = setTimeout(() => {
                    getSingleDocSearch(categoryId, searchText);
                }, 300);
            } else {
                setOpen(false);
            }
        }

        return () => clearTimeout(interval);
    }, [categoryId, searchText, isSearch]);

    useEffect(() => {
        if (!!categoryId) return;
        let interval;

        document.querySelector("#txtSearch").addEventListener("keypress", function (e) {
            if (e.key === "Enter") {
                if (!!searchText) {
                    setOpen(true);
                    setLoading(true);
                    interval = setTimeout(() => {
                        getAllDocsSearch(searchText);
                    }, 300);
                } else {
                    setOpen(false);
                }
            }
        });

        if (!!isSearch) {
            if (!!searchText) {
                setOpen(true);
                setLoading(true);
                interval = setTimeout(() => {
                    getAllDocsSearch(searchText);
                }, 300);
            } else {
                setOpen(false);
            }
        }

        return () => clearTimeout(interval);
    }, [searchText, categoryId, isSearch]);

    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            if (dropRef.current && !dropRef.current.contains(e.target)) {
                setOpen(false);
            }
        });
    }, []);

    return (
        <div
            ref={dropRef}
            className="jlt-docs-search jlt-bg-[#FFFFFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[44px] jlt-shadow-[0px_0px_10px_rgba(173,173,188,0.1)] jlt-pl-6 jlt-pr-1 jlt-flex jlt-justify-between jlt-items-center jlt-gap-2 jlt-relative !jlt-max-w-[350px] sm:!jlt-max-w-[600px]">
            <div className="jlt-h-full jlt-w-full jlt-flex jlt-gap-6">
                <select
                    defaultValue={"allDocs"}
                    className="jlt-w-[88px] jlt-px-2 jlt-py-3 jlt-text-sm jlt-text-[#475467] jlt-font-normal jlt-outline-none jlt-bg-[#ffff]"
                    onChange={(e) => setCategoryId(e.target.value)}>
                    <option value={""}>All Docs</option>
                    {parentCategories?.map((pCategory) => (
                        <option key={pCategory?.id} value={pCategory?.id}>
                            {pCategory?.name}
                        </option>
                    ))}
                </select>
                <input
                    id="txtSearch"
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    placeholder="Documentation Search..."
                    className="jlt-h-[44px] jlt-w-full jlt-text-sm jlt-text-[#72798A] jlt-font-normal jlt-outline-none"
                />
            </div>
            <button
                onClick={() => {
                    searchText ? setOpen(true) : null;
                    setIsSearch(true);
                }}
                className="jlt-py-2 jlt-px-4 jlt-bg-[--primary] jlt-rounded-[33px] jlt-text-sm jlt-text-[#FFFFFF] jlt-font-semibold jlt-flex jlt-items-center jlt-justify-center jlt-gap-2">
                <span className="jlt-hidden sm:jlt-block">Search</span>
                {getStyledIcon(getIcon("search"), "white", "stroke")}
            </button>

            {open ? (
                <div className="jlt-w-[100%] jlt-min-h-[50px] jlt-bg-white jlt-p-4 jlt-absolute jlt-top-full jlt-left-0 jlt-z-10 jlt-shadow-[0px_18px_28px_rgba(173,173,188,0.15)] jlt-rounded-lg jlt-border jlt-border-[#EBEEF5] jlt-mt-2">
                    {isEmpty(searchData) && !loading ? (
                        <div className="jlt-text-center">
                            Nothing found! Try with another keyword!
                        </div>
                    ) : loading ? (
                        <div className="jlt-text-center">Loading...</div>
                    ) : (
                        <ul>
                            {searchData?.map((data, index) => {
                                const category =
                                    data?.[`${pageSlug}DocsCategories`]?.nodes[0]?.name;
                                const categorySlug =
                                    data?.[`${pageSlug}DocsCategories`]?.nodes[0]?.slug;
                                const parentCategory =
                                    data?.[`${pageSlug}DocsCategories`]?.nodes[0]?.parent?.node
                                        ?.name;
                                const parentCategorySlug =
                                    data?.[`${pageSlug}DocsCategories`]?.nodes[0]?.parent?.node
                                        ?.slug;

                                return (
                                    <li key={index} className="jlt-px-4 jlt-py-1">
                                        {parentCategory ? (
                                            <Link
                                                href={`/docs/${parentCategorySlug}/${categorySlug}/${data?.slug}`}
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                                className="jlt-text-[--primary] hover:jlt-text-[--primary]">
                                                {data?.title}
                                            </Link>
                                        ) : (
                                            <Link
                                                href={`/docs/${categorySlug}/${data?.slug}`}
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                                className="jlt-text-[--primary] hover:jlt-text-[--primary]">
                                                {data?.title}
                                            </Link>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            ) : null}
        </div>
    );
};
